import './BackupInfoPage.css';
import INTEGRATION_IMG from '../../resources/images/integration-solutions.png'
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePen, faPersonCircleCheck, faFolderTree, faVirusCovidSlash, faCircleNodes } from '@fortawesome/free-solid-svg-icons'
function BackupInfoPage() {
    return (
        <div >
            <Navbar></Navbar>
            <div className={"background-filter"} style={{ color: "white" }}>
                <div className={"container"}>
                    <div>
                        <div className={"margins"} style={{ paddingTop: "20%" }}>
                            <div className={"block-column"}></div>
                            <div className={"container centered backupinfopage-text-centered"} >
                                <h1 style={{ fontSize: "44px" }}>
                                    <strong>
                                        Servicii de Back-up centralizat, arhivare, criptare
                                    </strong>
                                </h1>
                                <div className={"text"}>
                                    <strong>Platforma asigură backup-ul automatizat centralizat</strong> atat de pe unitățile de lucru, prin configurarea clientului desktop,
                                    cât și folosind protocoale standard pentru sincronizarea fișierelor, de pe unitățile de lucru sau servere.
                                    Platforma beneficiază atât de interfețe web, intuitive, cât și de aplicație desktop, care permite acțiuni complexe de lucru.
                                    Aplicația desktop permite backup-ul automat al fișierelor de pe unitățile de lucru, precum și funcționalități cum ar fi cele de partajare, versionare.
                                </div>
                                <div className={"centered"}>
                                    <a className={"link-button backupinfopage-text-centered"} style={{ margin: "30px", width: "150px" }} href={"/contact"}>Cerere oferta</a>
                                </div>
                            </div>
                            <div className={"block-column"}></div>
                        </div>
                    </div>
                </div>
                <div className={"container text-header backupinfopage-header"} style={{ maxWidth: "-webkit-fill-available", marginTop: "250px" }}>
                    <h3 style={{ fontSize: "30px", maxWidth: "-webkit-fill-available" }}>
                        <strong>
                            APLICATII SI MODULE SOFTWARE
                        </strong>
                    </h3>
                </div>
                <div className={"container"}>
                    <div className={"centered backupinfopage-text-centered"} style={{ gap: "100px" }}>
                        <div>

                        </div>
                        <AnimationOnScroll animateIn="animate__fadeInLeftBig">
                            <div className={"text centered flex-column"} style={{ gap: "30px" }}>
                                <div>
                                    <FontAwesomeIcon icon={faFilePen} style={{ fontSize: "100px" }}></FontAwesomeIcon>
                                </div>
                                <div>
                                    <strong>Aplicatie editare in-line documente. </strong>Sistemul permite editarea documentelor online, incluzand majoritatea functionalitatilor din suita Office. Acest sistem ruleaza local, de pe serverele aplicatiei.
                                </div>
                            </div>
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__fadeInLeftBig">
                            <div className={"text centered flex-column"} style={{ gap: "30px" }}>
                                <div>
                                    <FontAwesomeIcon icon={faPersonCircleCheck} style={{ fontSize: "100px" }}></FontAwesomeIcon>
                                </div>
                                <div>
                                    <strong>Aplicatie aprobare documente. </strong>Modulul de aprobare documente ofera posibilitatea introduceri unei optiuni care permite unui utilizator, pentru un anumit tip de documente, sa ceara aprobare. Doar dupa verificarea documentlui, acesta va putea fi folosit/trimis mai departe.Un exemplu practic ar fi redactarea unui document de catre o persoana, iar doar dupa verificarea acestuia de catre un superior ierarhic, sa poata fi trimis mai departe.Acest modul implica introducerea unor procese de lucru simple, pentru care exista doar posibilitatea aprobarii/refuzului.
                                </div>
                            </div>
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__fadeInLeftBig">
                            <div className={"text centered flex-column"} style={{ gap: "30px" }}>
                                <div>
                                    <FontAwesomeIcon icon={faFolderTree} style={{ fontSize: "100px" }}></FontAwesomeIcon>
                                </div>
                                <div>
                                    <strong>Aplicatia OCR. </strong>Permite ocr-izarea din imagini, permitand o cautare avansata in textul continut de imagini. Modulul are capabilitati de scanare si a documentelor PDF, scanate, ne-ocr-izate, permitand cautarea in acestea, cum ar fi documente din arhiva  electronica, scanari registratura, facturi etc.
                                </div>
                            </div>
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__fadeInLeftBig">
                            <div className={"text centered flex-column"} style={{ gap: "30px" }}>
                                <div>
                                    <FontAwesomeIcon icon={faVirusCovidSlash} style={{ fontSize: "100px" }}></FontAwesomeIcon>
                                </div>
                                <div>
                                    <strong>Aplicatie verificare ransomware. </strong> Acest modul securizeaza infrastructura in cazul unui atac ransomware, prin securizarea documentelor si restaurarea lor ulterioara.
                                </div>
                            </div>
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__fadeInLeftBig">
                            <div className={"text centered flex-column"} style={{ gap: "30px", paddingBottom: "100px" }}>
                                <div>
                                    <FontAwesomeIcon icon={faCircleNodes} style={{ fontSize: "100px" }}></FontAwesomeIcon>
                                </div>
                                <div>
                                    <strong>
                                        Module pentru interconectarea cu aplicatii terte. </strong> Necesita implementare in functie de specificul fiecarui client. Aceste module reprezinta unn set de interfete programabile, care permit preluarea sau primirea de date din aplicatii externe. Un exemplu practic ar fi salvarea automata dintr-o adresa de email a documentelor de tip .pdf, care vin de la o anumita adresa de email sau care contin in titlu anumite cuvinte cheie.
                                </div>
                            </div>
                        </AnimationOnScroll>
                    </div>
                </div>

            </div>
            {/* <div className={"container text-header backupinfopage-header"} style={{ maxWidth: "-webkit-fill-available" }}>
                <h3 style={{ fontSize: "30px", maxWidth: "-webkit-fill-available" }}>
                    <strong>
                        Integrare si verificare a datelor de backup
                    </strong>
                </h3>
            </div> */}
            <div className={"container"} style={{marginTop:"50px"}}>
                <div className={"flex-column"} style={{display:"flex"}}>
                    <div className={"integration-photo-container"}>
                        <img className={"integration-photo"} src={INTEGRATION_IMG} ></img>
                    </div>
                    <div className={"text integration-text"}>
                        <div>
                            <div>
                                <h3 style={{ fontSize: "30px"}}>
                                    <strong>
                                        Module pentru interconectarea cu aplicatii terte
                                    </strong>
                                </h3>
                            </div>
                            <div>
                                ale clientului, necesita implementare in functie de specificul fiecarui client. Aceste module reprezinta unn set de interfete programabile, care permit preluarea sau primirea de date din aplicatii externe. Un exemplu practic ar fi salvarea automata dintr-o adresa de email a documentelor de tip .pdf, care vin de la o anumita adresa de email sau care contin in titlu anumite cuvinte cheie.
                            </div>
                        </div>
                        <div>
                            <div>
                                <h3 style={{ fontSize: "30px"}}>
                                    <strong>
                                    Modul de verificare a integritatii datelor de pe serverul offline
                                    </strong>
                                </h3>
                            </div>
                            <div>
                            Acest modul permite verificarea periodica a integritatii datelor de pe serverul de backup offline, iar in cazul de anomalii sunt generate alerte. Integritatea serverului de backup offline este importanta, deoarece reprezinta ultimul nivel din care datele pot fi recuperate, restaurate. Sistemul permite recuperarea unei structuri din orice zi in care s-a facut backup offline.
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className={"container"} style={{marginTop:"50px", marginBottom:"30px"}}>
                <div className={"flex-column"} style={{display:"flex"}}>
                    <div className={"integration-photo-container"} style={{flex:"1"}}>
                    <h3 style={{ fontSize: "30px", display:"flex"}}>
                                    <strong>
                                    Infrastructura pentru Backup Offline
                                    </strong>
                                </h3>
                    </div>
                    <div className={"text integration-text"} style={{flex:"2"}}>
                        <div>
                            <div>
                            Sistemul permite un model avansat de salvare a documentelor, bazat pe chunk-uri, care permite stocarea fisierelor duplicat intr-un singur segment de disc, ceea ce duce la o economisire a spatiului de stocare. Sistemul permite un backup zilnic al intregii infrastructuri, permitand restaurarea intregului sistem, unui utilizator, unui director, grup de fisiere din orice zi de la pornirea platformei pana in momentul prezent. In cazul in care limitele de stocare sunt depasite, sistemul poate fi configurat sa pastreze doar ultimele N backupuri, a.i. va exista o permaneneta continuitate.                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <Footer></Footer>
        </div>
    );
}

export default BackupInfoPage;

import './Homepage.css';
import IMAGE from "../../resources/images/homepageimage.jpg"
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';

function Homepage() {
  return (
    <div className={"homepage-wrapper"}>
      <Navbar></Navbar>
      <div >
        <div className={"homepage-cover homepage-cover-filter alignfull pos-absolute"}></div>
        <div className={"homepage-cover homepage-cover homepage-cover-image alignfull pos-absolute"}> </div>
        <div className={"homepage-text"} style={{position:"relative"}}>

          <div className={"h1"} style={{fontZize: 1.125}}>
            Servicii de Back-up centralizat, arhivare, criptare
          </div>

          <div>
            + Servicii pentru testare a securității informatice a infrastructuri IT
          </div>

          <div><a className={"link-button"} href="/back-up-centralizat-arhivare-criptare">Citeste mai multe</a></div>

        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Homepage;

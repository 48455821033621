import '../../index.css';
import './Footer.css';

function Footer() {

  return (
    <div className={"footer-bottom"}>
        <div className={"container"} style={{paddingTop: "20px", width: "auto"}}>
            Copyright © {new Date().getFullYear()} INFO ELEMAR
        </div>
    </div>
  );
}

export default Footer;

import './advantagespage.css';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faServer, faLaptop, faShieldHalved, faMaximize, faRectangleList, faPen, faCalendarCheck, faCalendarXmark, faPlay } from '@fortawesome/free-solid-svg-icons'

function AdvantagesPage() {
    return (
        <div >
            <Navbar></Navbar>
            <div className={"container text-header"} style={{ maxWidth: "-webkit-fill-available", margin: "50px 0px", textAlign: "center" }}>
                <div className={"h1"}>
                    <strong>
                        Avantaje competitive
                    </strong>
                </div>
            </div>
            <div className={"container text advantages"} style={{marginBottom:"30px"}}>
                <div className={"advantage"}>
                    <div>
                        <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                    </div>
                    <div>
                        <div>
                            <strong>
                                Scalabilitate nelimitată
                            </strong>
                        </div>
                        <div>
                            Indiferent de mărimea rețelei locale, de numărul de calculatoare sau a numărului de utilizatori, Platforma software asigură pentru toate aplicațiile posibilitatea de dezvoltare și creștere, neexistând nici o limitare tehnică. Echipa noastră asigura în cadrul proiectului și consultanță pentru dimensionarea infrastructurii hardware.
                        </div>
                    </div>
                </div>

                <div className={"advantage"}>
                    <div>
                        <FontAwesomeIcon icon={faServer}></FontAwesomeIcon>
                    </div>
                    <div>
                        <div>
                            <strong>
                                Platforma software se instalează pe serverele companiei dumneavoastră
                            </strong>
                        </div>
                        <div>
                            asigurând un nivel ridicat de confidențialitate a informațiilor tranzitate prin rețea, sau stocate pe servere. Infrastructura hardware necesară nu implică investiții majore. La nivel de servere Platforma software se configurează în funcție de disponibilitățile existente. Sistemul de operare pentru servere este Linux. La nivelul work station, aplicația desktop nu consumă resurse, putând fi instalată pe sisteme de operare începând cu Windows 7 (32/64 biți). Accesul web-based este disponibil pe orice sistem de operare.
                        </div>
                    </div>
                </div>

                <div className={"advantage"}>
                    <div>
                        <FontAwesomeIcon icon={faLaptop}></FontAwesomeIcon>
                    </div>
                    <div>
                        <div>
                            <strong>

                                Aplicația desktop
                            </strong>
                        </div>
                        <div>
                            integrează într-o singură interfață toate functionalitatiile Platformei software. Se instalează rapid și este foarte ușor de folosit pentru acțiuni complexe de lucru.
                        </div>
                    </div>
                </div>

                <div className={"advantage"}>
                    <div>
                        <FontAwesomeIcon icon={faShieldHalved}></FontAwesomeIcon>
                    </div>
                    <div>
                        <div>
                            <strong>
                                Securitate sporită
                            </strong>
                        </div>
                        <div>
                            Accesul în aplicație este restricționat diferențiat, în funcție de nivelul necesar. Administrarea poate fi restricționata în funcție de IP, precum și în funcție de user și parola. Utilizatorii folosiți în aplicație pot fi cei din structura de Active Directory existentă, LDAP etc. Aplicația oferă și alte posibilități de management al utilizatorilor, cu funcționalități facile de creare și gestiune de: Conturi de utilizator; Roluri de utilizator; Drepturi și permisiuni la nivel de funcționalități; Drepturi și permisiuni la nivel de date. Sistemul permite multiple tipuri de autentificări: Forms-Authentication (Autentificarea Individuală), Active Directory Authentication, LDAP. Aplicația poate fi configurată și pentru a fi accesată securizat de un nivel mai ridicat decât cel standard, respectiv doar din interiorul rețelei sau prin acces securizat prin VPN.
                        </div>
                    </div>
                </div>

                <div className={"advantage"}>
                    <div>
                        <FontAwesomeIcon icon={faMaximize}></FontAwesomeIcon>
                    </div>
                    <div>
                        <div>
                            <strong>
                                Flexibilitate maximă
                            </strong>
                        </div>
                        <div>
                            Platforma software poate fi folosită atât din interfața web cat mai ales prin intermediul aplicației desktop, care integrează într-un mod foarte accesibil toate funcționalitățile Platformei software. Aplicația poate fi instalată pe sisteme de operare Windows sau Linux.
                        </div>
                    </div>
                </div>

                <div className={"advantage"}>
                    <div>
                        <FontAwesomeIcon icon={faRectangleList}></FontAwesomeIcon>
                    </div>
                    <div>
                        <div>
                            <strong>
                                Integrarea cu alte aplicații existente
                            </strong>
                        </div>
                        <div>
                            Un aspect important este capacitatea de interoperabilitate cu alte aplicații sau posibilitatea de integrare în Platforma software a altor aplicații, astfel încât toate funcționalitățile Platformei software sa fie extinse.
                        </div>
                    </div>
                </div>

                <div className={"advantage"}>
                    <div>
                        <FontAwesomeIcon icon={faPen}></FontAwesomeIcon>
                    </div>
                    <div>
                        <div>
                            <strong>
                                Personalizarea Platformei software
                            </strong>
                        </div>
                        <div>
                            Intreaga suita de aplicații din cadrul Platformei software este personalizata cu identitatea (logo, grafica, denumire etc.) instituției dumneavoastră.
                        </div>
                    </div>
                </div>
                <div className={"advantage"}>
                    <div>
                        <FontAwesomeIcon icon={faCalendarCheck}></FontAwesomeIcon>
                    </div>
                    <div>
                        <div>
                            <strong>
                                Instalarea și configurarea întregului Sistem informatic durează intre 3 și 7 zile lucrătoare
                            </strong>
                        </div>
                        <div>
                            în funcție de numărul de calculatoare din cadrul rețelei locale a companiei dumneavoastră
                        </div>
                    </div>
                </div>

                <div className={"advantage"}>
                    <div>
                        <FontAwesomeIcon icon={faPlay}></FontAwesomeIcon>
                    </div>
                    <div>
                        <div>
                            <strong>
                                Operaționalizarea Platformei software
                            </strong>
                        </div>
                        <div>
                            va asigura un nivel crescut de digitalizare a organizatiei, având ca obiective migrarea într-un sistem informatic care poate acoperi principalele activități specifice și eficientizarea întregii activități într-un timp foarte scurt.
                        </div>
                    </div>
                </div>

                <div className={"advantage"}>
                    <div>
                        <FontAwesomeIcon icon={faCalendarXmark}></FontAwesomeIcon>
                    </div>
                    <div>
                        <div>
                            <strong>
                                Sistemul informatic a fost proiectat pentru a contracara pierderi ireversibile de date și informații importante din cadrul institutiei dumneavoastră.
                            </strong>
                        </div>
                        <div>
                            Platforma permite integrarea capabilităților de backup offline, ceea ce înseamnă ca datele pot fi sincronizate periodic cu un server de stocare, fără acces la internet, echipament ce permite recuperarea totala a informațiilor în caz de compromitere fizică a serverelor aflate în producție.
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
}

export default AdvantagesPage;

import './contactpage.css';
import GENERAL_INFO from "../../resources/messages/general.json";
import INTEGRATION_IMG from '../../resources/images/integration-solutions.png'
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import Mailing from '../../components/mailing/mailing';

function ContactPage() {

    return (
        <div className={"homepage-wrapper"}>
            <Navbar></Navbar>
            <div className={"container text"} >
                <div>
                    <div className={"pos-relative"}>
                        <div className={"image-wrapper background-filter alignfull pos-absolute contact-header-height "}></div>
                        <div className={"background-image-text pos-relative contact-header-height "} style={{ textAlign: "center" }}>
                            <div className={"h1"}>
                                Contact
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"container text contactpage-info-content"} style={{ marginTop: "30px" }}>
                    <div>
                        <div className={"h2"} style={{ marginBottom: "30px" }}>
                            <strong>
                                INFO ELEMAR
                            </strong>
                        </div>

                        <div>
                            <div className={"h3"} style={{ marginBottom: "30px" }}>
                                <strong>Sediul social</strong>
                            </div>

                            <div style={{ marginBottom: "30px" }}>
                                <FontAwesomeIcon icon={faLocationDot} style={{ fontSize: "25px", margin: "0 10px" }}></FontAwesomeIcon> {GENERAL_INFO.location.county}, {GENERAL_INFO.location.city}, {GENERAL_INFO.location.street}
                            </div>
                        </div>

                        <div>
                            <div className={"h3"} style={{ marginBottom: "30px" }}>
                                <strong>Punct de lucru</strong>
                            </div>
                            <div>

                                <div style={{ marginBottom: "30px" }}>
                                    <FontAwesomeIcon icon={faLocationDot} style={{ fontSize: "25px", margin: "0 10px" }}></FontAwesomeIcon> {GENERAL_INFO.location.county}, {GENERAL_INFO.location.city}, {GENERAL_INFO.location.street}
                                </div>

                                <div style={{ marginBottom: "30px" }}>
                                    <FontAwesomeIcon icon={faPhone} style={{ fontSize: "25px", margin: "0 10px" }}></FontAwesomeIcon> {GENERAL_INFO.phone}
                                </div>

                                <div style={{ marginBottom: "30px" }}>
                                    <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: "25px", margin: "0 10px" }}></FontAwesomeIcon> {GENERAL_INFO.email}

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={"text contactpage-mailing-margin"}>
                        <div className={"h2"} style={{marginBottom:"30px"}}>
                            <strong >Trimite-ne un mail</strong>
                        </div>
                        <Mailing></Mailing>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
}

export default ContactPage;

import './Navbar.css';
import '../../index.css';
import LOGO from '../../resources/images/logo.svg';
import MESSAGES from "../../resources/messages/navbar.json";

import Dropdown from '../dropdown/Dropdown';
import Sidebar from '../sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames';
import { useState, useEffect } from 'react';

function Navbar(props) {
  const [openSidebar, setOpenSidebar] = useState(false);


  const createNavigationLinks = () => {
    return MESSAGES.map((element,index) => {
      if (element != null) {
        switch (element.type) {
          case "link": return <div key={index}><a className={classNames({ "navbar-link": true, "highlight-link": window.location.pathname == element.link })} href={element.link}>{element.title}</a></div>;
          case "button": return <div key={index}><a className={"link-button"} href={element.link}>{element.title}</a></div>;
          case "dropdown": return <div key={index}><Dropdown settings={{ title: element.title, options: element.sub_links }}></Dropdown></div>;
        }
      }
    });
  }

  const isSidebarOpen = () => {
    setOpenSidebar(!openSidebar);
  }

  return (
    <div className={"navbar"}>
      <div className={"navbar-image"} >
        <img className={"navbar-logo-hover"} style={{width:"200px", height:"70px"}} src={LOGO}/>
      </div>
      <div className={"centered-container"}>
        <div className={"navbar-links"}>
          {createNavigationLinks()}
        </div>
        <div>
          <a className={"navbar-menu-bars"}>
            <FontAwesomeIcon icon={faBars} onClick={() => {isSidebarOpen()}}></FontAwesomeIcon>
          </a>
        </div>
      <Sidebar open={openSidebar} options={MESSAGES} ></Sidebar>
      </div>
    </div>
  );
}

export default Navbar;

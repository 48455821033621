import './mailing.css';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import env from "react-dotenv";

function Mailing(props) {
  const form = useRef();

  const sendEmail = (e) => {
    if (document.getElementById("message").value.length <= 0 ||
        document.getElementById("user_email").value.length <= 0 ||
        document.getElementById("user_name").value.length <= 0) {
      return;
    }
    console.log(document.getElementById("user_name").value.length);
    e.preventDefault();
    emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <form ref={form} onSubmit={sendEmail} className={"text mailing-wrapper"} style={{marginBottom:"30px"}}>
      <div>
        <label><strong>Nume</strong></label>
        <input type="text" name="user_name"  id="user_name" />
      </div>
      <div>
        <label><strong>Email</strong></label>
        <input type="email" name="user_email" id="user_email" />
      </div>

      <div>
        <label><strong>Mesaj</strong></label>
        <textarea name="message" id="message"/>
      </div>
      <input className={"link-button send-button"} type="submit" value="Trimite" />
    </form>
  );
};
export default Mailing;

import './informationsecuritypage.css';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine, faPeopleGroup, faNetworkWired } from '@fortawesome/free-solid-svg-icons'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";

function InformationSecurityPage() {
  return (
    <div className={"homepage-wrapper"}>
      <Navbar></Navbar>
      <div className={"container "}>
        <div className={"pos-relative"}>
          <div className={"image-wrapper background-filter alignfull pos-absolute"}></div>
          <div className={"background-image-text pos-relative"} style={{ textAlign: "center" }}>
            <div className={"h1"}>
              Servicii pentru testare a securității informatice a infrastructuri IT
            </div>
            <div className={"h3"}>
              rețele locale cu echipamente pasive și active, stații de lucru, servere
            </div>
          </div>
        </div>
      </div>
      <div className={"container text-header"} style={{ maxWidth: "-webkit-fill-available", margin: "50px 0px" }}>
        <h3 style={{ fontSize: "30px", maxWidth: "-webkit-fill-available" }}>
          <strong>
            APLICATII SI MODULE SOFTWARE
          </strong>
        </h3>
      </div>
      <div className={"container text products-mobile"} style={{ display: "flex", gap: "2em", marginBottom: "50px" }}>
        <div className={"product-info"}>
          <div style={{ marginBottom: "20px" }}>
            <FontAwesomeIcon icon={faNetworkWired} style={{ fontSize: "100px" }}></FontAwesomeIcon>
          </div>
          <div>
            Un sistem format dintr-un senzor tip FasterUP UTM – Unified Threat Management instalat în rețelele beneficiarului, care rulează o suita de aplicații în vederea identificării vulnerabilităților din rețeaua locala a beneficiarului.
          </div>
        </div>

        <div className={"product-info"}>
          <div style={{ marginBottom: "20px" }}>
            <FontAwesomeIcon icon={faPeopleGroup} style={{ fontSize: "100px" }}></FontAwesomeIcon>
          </div>
          <div>
            O echipă de analiști de securitate cibernetică modelează și monitorizează întreg procesul de scanare. Se vor realiza multiple scanări la ore și zile diferite. De asemenea se va monitoriza activitatea din rețeaua locală 14 zile calendaristice.
          </div>
        </div>

        <div className={"product-info"}>
          <div style={{ marginBottom: "20px" }}>
            <FontAwesomeIcon icon={faChartLine} style={{ fontSize: "100px" }}></FontAwesomeIcon>
          </div>
          <div>
            In urma realizării procesului de scanare și de monitorizare a activităților din rețeaua locală, urmează analizarea datelor și realizarea unui raport precis care tratează fiecare vulnerabilitate identificată. Raportul va conține recomandări și măsuri concrete pentru înlăturarea vulnerabilităților.
          </div>
        </div>
      </div>
      <div className={"container"}>
        <div className={"pos-relative"}>
          <div className={"image-wrapper background-filter alignfull pos-absolute mobile-height"}></div>
          <div className={"background-image-text pos-relative"} style={{ textAlign: "center" }}>
            <div>
              <div className={"h3"} style={{ marginBottom: "50px" }}>
                Specificații funcționale și tehnice
              </div>
              <div>
                <AnimationOnScroll animateIn="animate__fadeInLeftBig" style={{ marginBottom: "20px" }}>
                  <div>
                    In fiecare rețea locală se va instala câte un senzor FasterUP UTM – Unified Threat Management, iar toate datele scanărilor vor fi centralizate.
                  </div>
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__fadeInRightBig" style={{ marginBottom: "20px" }}>
                  <div>
                    Scanarea pentru identificarea vulnerabilităților tuturor echipamentelor active și pasive de rețea, se efectuează utilizând ultimele tehnologii și definiții de vulnerabilități. Acestea sunt integrate automat sau personalizate la nivel de client în funcție de cerințele acestuia.
                  </div>
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__fadeInLeftBig" style={{ marginBottom: "50px" }}>
                  <div>
                    Se vor livra rapoarte privind vulnerabilitățile de rețea, și calea sau informațiile pentru remedierea acestor vulnerabilități, la nivel de rețea sau de echipament.
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
            <div>
              <div className={"h3"} style={{ marginBottom: "50px" }}>
                Integrare de reguli personalizate de scanare, adaptate aplicațiilor dezvoltate intern
              </div>
              <div>
                <AnimationOnScroll animateIn="animate__fadeInLeftBig" >
                  <div>
                    La cererea beneficiarului se pot dezvolta, concepe noi reguli de scanare de vulnerabilități, adaptate conform specificului infrastructurii IT a acestuia, în concordanță cu tipul de aplicații sau echipamente pe care le utilizează.
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
            <div>

            </div>
          </div>
        </div>
      </div>
      <div className={"container text-header"} style={{ maxWidth: "-webkit-fill-available", margin: "50px 0px", textAlign: "center" }}>
        <h3 style={{ fontSize: "30px", maxWidth: "-webkit-fill-available" }}>
          <strong>
            Metodologii de clasificare a vulnerabilităților și a tipurilor de posibile exploatări a acestora
          </strong>
        </h3>
      </div>
      <div className={"container text"} style={{ display: "flex", flexDirection: "column", gap: "20px", textAlign: "center", marginBottom:"30px"}}>
        <div>
          Clasificarea vulnerabilităților și a atacurilor se face după mai multe criterii cum ar fi: modul de desfășurare, vulnerabilitatea exploatată, obiectivul atacului, motivația atacatorului, impactul și implicațiile atacului, resursa atacată, elementele de securitate afectate. Senzorul FasterUP – UTM beneficiază de funcționalitățile care ii permit ca în procesul de evaluare și analiză a noilor amenințări să se utilizeze un model de clasificare ce încorporează atribute specifice monitorizării securității foarte complexe. Acesta va indica zona în care intruziunea se va putea detecta (în cazul în care mecanismul de protecție eșuează), precum și procesele conexe cadrului de securitate necesare pentru implementarea mecanismului defensiv (de exemplu: managementul patch-urilor) sau izolarea echipamentelor compromise.
        </div>
        <div>
          O abordare formală are rolul de a oferi o perspectivă consistentă care ia în calcul toate aspectele de interes ale beneficiarului. O exemplificare a utilizării modelului propus pentru evaluarea vulnerabilităților este ilustrată prin acordarea unui scor CVSS (Common Vulnerability Scoring System).
        </div>
        <div className={"centered"}>
          <a className={"link-button backupinfopage-text-centered"} style={{ margin: "30px", width: "150px" }} href={"/contact"}>Cerere oferta</a>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default InformationSecurityPage;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Homepage from './pages/homepage/Homepage';
import reportWebVitals from './reportWebVitals';
import BackupInfoPage from './pages/backupinfopage/BackupInfoPage';
import InformationSecurityPage from './pages/informationsecuritypage/informationsecuritypage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AdvantagesPage from './pages/advantagespage/advantagespage';
import ContactPage from './pages/contact/contactpage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <BrowserRouter>
      <Routes>
        <Route path="/back-up-centralizat-arhivare-criptare" element={<BackupInfoPage></BackupInfoPage>} />\
        <Route path="/securitate-informatica" element={<InformationSecurityPage></InformationSecurityPage>} />
        <Route path="/avantaje-competitive" element={<AdvantagesPage></AdvantagesPage>} />
        <Route path="/contact" element={<ContactPage></ContactPage>} />
        <Route path="/" element={<Homepage />}>

        </Route>
      </Routes>
    </BrowserRouter>
    
  </React.StrictMode>
);
import './Sidebar.css';
import '../../index.css';
import { useState, useEffect, useRef } from 'react';
import Dropdown from '../dropdown/Dropdown';
import classNames from 'classnames';

function Sidebar(props) {
    const wrapperRef = useRef(null);
    const [hasBeenInit, setHasBeenInit] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(0);
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
          if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            closeSidebar();
          }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [wrapperRef]);

    useEffect(() => {
        if (hasBeenInit == false) {
            setHasBeenInit(true);   
        }
        else {
            setSidebarWidth(230);

        }
    }, [props.open])


    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
            if (window.innerWidth > 960) {
                closeSidebar();
            }
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const closeSidebar = () => {
        setSidebarWidth(0);
    }

    const createNavigationLinks = () => {
        return props.options.map((element,index) => {
          if (element != null) {
            switch (element.type) {
              case "link": return <div key={index}><a className={classNames({ "navbar-link": true, 
                                                                              "navbar-highlight": window.location.pathname == element.link,
                                                                              "highlight-link" : window.location.pathname == element.link  })}
                                                    href={element.link}>{element.title}
                                                    </a>
                                    </div>;
              case "button": return <div key={index}><a className={"link-button"} href={element.link}>{element.title}</a></div>;
              case "dropdown": return <div key={index}><Dropdown settings={{ title: element.title, options: element.sub_links }} dropdown_hover={true}></Dropdown></div>;
            }
          }
        });
      }

    return (
        <div ref={wrapperRef}>
            <div id="mySidenav" class="sidenav" style={{ width: sidebarWidth }}>
                <a class="closebtn" onClick={closeSidebar}>&times;</a>
                {createNavigationLinks()}
            </div>
        </div>
    );
}

export default Sidebar;

import './Dropdown.css';
import '../../index.css';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react';

function Dropdown(props) {
    const [areOptionsVisible, setAreOptionsVisible] = useState(false);

    const createOptions = () => {
        if (props.settings == {} || props.settings == null) {
            return [];
        }
        return props.settings.options.map((elem, index) => {
            return <a className={classNames({"highlight-link": window.location.pathname == elem.link })} key={index} href={elem.link}>{elem.title}</a>;
        })
    }

    return (
        
        <div className={classNames({"dropdown" : true, "max-width" : props.dropdown_hover})} >
            <a className={"dropdown-title"} onClick={() => {setAreOptionsVisible(!areOptionsVisible)}}>
                {props.settings.title}
                <span className={"dropdown-triangle-container"}>
                    <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                </span>

            </a>
            <div className={classNames({ "dropdown-options-hover": !props.dropdown_hover, 
                                    "dropdown-options": props.dropdown_hover,
                                    "show-options" : areOptionsVisible })}>
                {createOptions()}
            </div>
        </div>
    );
}

export default Dropdown;
